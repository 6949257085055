import React from "react"
import { navigate } from "gatsby";
import axios from "axios";

import moment from 'moment';

import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import SearchIcon from '@material-ui/icons/Search';

import { TextField } from "@material-ui/core";
import { Table, TableRow, TableBody, TableCell, TableHead, Paper, TableContainer, Grid, Button } from '@material-ui/core';

import { checkAuth, auth } from "../../helpers/auth";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

class LicenseForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      data: undefined,
      edited: undefined,
      machine_id: ''
    }

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangemachineId = this.handleChangemachineId.bind(this);
    this.handleNewRow = this.handleNewRow.bind(this);
    this.getLicences = this.getLicences.bind(this);
    this.add = this.add.bind(this);
    this.delete = this.delete.bind(this);
    this.save = this.save.bind(this);
  }



  async getLicences() {
    const { data } = await auth.get(process.env.SERVER_ENDPOINT + `/brainy_concepts_licencing/${this.state.email}`);

    const licences = {};
    const productsCount = {};

    data
      .sort((a, b) => a.expiration_date > b.expiration_date ? -1 : 1)
      .forEach(l => {
        const key = l.product_id + '#' + l.nt_machine_id;


        if (Object.keys(licences).includes(key)) {
          licences[key].rest.push(l);
        } else {
          if (Object.keys(productsCount).includes(l.product_id)) {
            productsCount[l.product_id]++;
          } else {
            productsCount[l.product_id] = 1;
          }
          licences[key] = { main: l, rest: [] };
        }
      })

    this.setState({ data: licences, productsCount });
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  handleChangemachineId(e) {
    this.setState({ machine_id: e.target.value });
  }
  handleNewRow(e) {
    const newItem = { ...this.state.new };
    newItem.nt_machine_id = e.target.value;
    this.setState({ new: newItem });
  }

  async add() {
    await auth.post(process.env.SERVER_ENDPOINT + `/brainy_concepts_licencing`, { ... this.state.new });
    await this.getLicences();
    this.setState({ new: undefined });
  }

  async delete(license_id) {
    await auth.delete(process.env.SERVER_ENDPOINT + `/brainy_concepts_licencing`, { license_id });
    await this.getLicences();
  }

  async save() {
    const { edited, machine_id } = this.state;

    const licenceKey = Object.keys(this.state.data).find(e => this.state.data[e].main.license_id == edited);
    const licence = this.state.data[licenceKey];

    const rest = licence.rest.map(r => r.license_id);

    const body = {
      license_ids: [edited, ...rest].join("','"),
      nt_machine_id: machine_id
    };
    await auth.put(process.env.SERVER_ENDPOINT + `/brainy_concepts_licencing`, body);
    await this.getLicences();

    this.setState({ edited: undefined, machine_id: '' });
  }

  render() {
    const newRow = this.state.new;
    return <>
      <h2>Manage licences</h2>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          label="Enter email"
          variant="outlined"
          style={{ flexGrow: '1', marginBottom: '20px' }}
          value={this.state.email}
          onChange={this.handleChangeEmail}
          onKeyPress={(ev) => ev.key === 'Enter' ? this.getLicences() : null}
        />
        <SearchIcon style={{ margin: '15px' }} onClick={this.getLicences} />
      </div>
      {this.state.data ?
        Object.keys(this.state.data).length > 0 ?
          <TableContainer component={Paper} style={{ overflow: 'visible' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">License ID</TableCell>
                  <TableCell align="center">Product ID</TableCell>
                  <TableCell >Machine ID</TableCell>
                  <TableCell align="right">Creation date</TableCell>
                  <TableCell align="right">Expiration Date</TableCell>
                  <TableCell style={{ width: '320px' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(this.state.data).map((key) => {
                  const row = this.state.data[key].main;
                  const main = (
                    <TableRow key={row.license_id}>
                      <TableCell align="right" component="th" scope="row">
                        {row.license_id}
                      </TableCell>
                      <TableCell align="center">{row.product_id}</TableCell>
                      <TableCell >
                        {this.state.edited == row.license_id ?
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <TextField
                              label="Machine ID"
                              variant="outlined"
                              style={{ flexGrow: '1' }}
                              value={this.state.machine_id}
                              onChange={this.handleChangemachineId}
                            />
                            <CheckIcon
                              style={{ margin: '0 15px', color: 'green', cursor: 'pointer' }}
                              onClick={() => this.save()}
                            />
                            <ClearIcon
                              style={{ opacity: '0.5', cursor: 'pointer' }}
                              onClick={() => this.setState({ edited: undefined, nt_machine_id: '' })}
                            />
                          </div>
                          : <>
                            {row.nt_machine_id} {this.state.data[key].rest.length > 0 ? '(+' + this.state.data[key].rest.length + ')' : ''}
                            <EditIcon
                              style={{ margin: '0 15px', fontSize: '18px', cursor: 'pointer' }}
                              onClick={() => this.setState({ edited: row.license_id, machine_id: row.nt_machine_id })}
                            />
                          </>
                        }
                      </TableCell>
                      <TableCell align="right">{moment(row.date_created).format('lll')}</TableCell>
                      <TableCell align="right">{moment(row.expiration_date).format('lll')}</TableCell>
                      <TableCell>
                        {this.state.productsCount[row.product_id] == '1' && !this.state.new &&
                          <button className="btn" variant="contained" color="primary" onClick={() => this.setState({ new: { ...row } })}>
                            <AddIcon style={{ cursor: 'pointer', fontSize: '20px', verticalAlign: 'bottom' }} />
                    License a Second Machine ID
                        </button>
                        }
                      </TableCell>
                    </TableRow>
                  )
                  const rest = this.state.data[key].rest.map(row => (
                    <TableRow style={{ opacity: '0.3' }} key={row.license_id}>
                      <TableCell align="right" component="th" scope="row">
                        {row.license_id}
                      </TableCell>
                      <TableCell align="center">{row.product_id}</TableCell>
                      <TableCell >{row.nt_machine_id}</TableCell>
                      <TableCell align="right">{moment(row.date_created).format('lll')}</TableCell>
                      <TableCell align="right">{moment(row.expiration_date).format('lll')}</TableCell>
                      <TableCell>
                        <DeleteForeverIcon
                          style={{ cursor: 'pointer', fontSize: '20px', verticalAlign: 'bottom' }}
                          onClick={() => this.delete(row.license_id)}
                        />
                      </TableCell>
                    </TableRow>
                  ));

                  return <>
                    {main}
                    {rest}
                  </>
                }
                )}
                {newRow &&
                  <TableRow>
                    <TableCell align="right" component="th" scope="row"></TableCell>
                    <TableCell align="center">{newRow.product_id}</TableCell>
                    <TableCell >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          label="Machine ID"
                          variant="outlined"
                          style={{ flexGrow: '1' }}
                          value={newRow.machine_id}
                          onChange={this.handleNewRow}
                        />
                        <CheckIcon
                          style={{ margin: '0 15px', color: 'green', cursor: 'pointer' }}
                          onClick={() => this.add()}
                        />
                        <ClearIcon
                          style={{ opacity: '0.5', cursor: 'pointer' }}
                          onClick={() => this.setState({ new: undefined })}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="right">{moment(newRow.date_created).format('lll')}</TableCell>
                    <TableCell align="right">{moment(newRow.expiration_date).format('lll')}</TableCell>
                    <TableCell style={{ width: '50px' }}></TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          : "No License Found for Email Address Entered!"
        : null}
    </>
  }
}

const Request = (props) => {

  const roles = checkAuth('brainy-concepts-licensing-portal');

  return (
    roles && roles.length > 0 &&
    <Layout showAvatar={true} showSidebar={true}>
      <SEO title={`Welcome - Choose App`} />

      <div style={{ padding: '20px' }}>
        <LicenseForm />
      </div>

    </Layout>
  )

}

export default Request
